.topSection {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  padding: 0em 3em;
}

.showOuter {
  position: relative;
  width: auto;
  display: inline-block;
}

.buttonContianers {
  position: relative;
}

.signOfficer_disable:hover .showMessage {
  visibility: visible;
} 

.showMessage {
  visibility: hidden;
  width: auto;
  background-color: #666;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  top: 10px;
  right: 0;
}

.success-message{
  color: #80C343;
  padding-left: 10px;
}


.uploadContractButtonDisable{
  cursor: not-allowed;
  pointer-events: none; 
  opacity: 0.6;
}


.buttonTooltip {
  padding: 20px 40px;
  background-color: #80C343;
  border: none;
  margin-left: 24px;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: white;
  border-radius: 8px;
  opacity: 0.6;
  cursor: not-allowed;
}

.uploadContractButton {
  padding: 20px 40px;
  background-color: #80C343;
  border: none;
  margin-left: 24px;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}



.saveDraftButton {
  border: 1px solid #80C343;
  background: #F8F8F8;
  border-radius: 8px;
  padding: 20px 40px;
  margin-left: 24px;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.titleofthePage {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0px;
  margin-left: 0.5em;
}

.buttonContianers {
  margin-right: 2em;
}

.navigationSection {
  display: flex;
  cursor: pointer;
}


.formSection {
  max-width: 800px;
  padding: 0 80px;
}

.labelStyle {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.3264px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin: 10px auto 10px auto;
}

.colorForError {
  color: red;
}

.errorBlock {
  display: flex;
  align-items: center;
}

.errorBlock img {
  height: 18px;
  width: 20px;
  padding-top: 5px;
}

.errorText {
  margin: 5px 0 0 10px;
  color: red;
  font-size: 14px !important;
}

.m10 {
  margin-left: 10px;
}

.inputField {
  padding: 20px 20px;
  margin-top: 1em;
}

.inputContainer {
  margin: 0.5em 0em;
}

.adminManualInputContainer {
  display: flex;
  margin-top: 0.5em;
}

.adminManualInputContainerInner {
  display: flex;
  margin-right: 2em;
}

#changedriverIdtoHrID {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.adminManualInputContainerInner input,
.changedriverIdtoHrIDContainer input {
  margin-top: 0.3em;
}

.signOfficer {
  border: none;
  outline: none;
  text-align: right;
  margin: 20px 0 20px;
  cursor: pointer;
  background: inherit;
}

.calendarIconInput {
  position: absolute;
  top: 1em;
  right: 20px;
  z-index: 0;
}

.signOfficer_disable {
  border: none;
  outline: none;
  cursor: not-allowed;
  text-align: right;
  margin: 20px 0 20px;
  background: inherit;
  width: auto;
  float: right;
}


#contractInfoCalendar .react-calendar {
  width: 320px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 1;
}

.calendarContainer {
  cursor: pointer;
  position: relative;
}

.posRelative {
  position: relative;
}


.processingUploadScreen .contractUploadScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 23em;
}


.processingUploadScreen div:first-child,
.contractUploadScreen div:first-child {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
}


.tooltipNew {
  position: relative;
  display: inline-block;
}

.tooltipNew .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  top: -20px;
  left: -6px;
}

.tooltipNew:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 1360px) {
  .navigationSection {
    margin-bottom: 20px;
  }

  .topSection {
    display: block;
    padding: 0 80px;
  }

  .saveDraftButton {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 20px;
    width: 210px;
  }

  .uploadContractButton {
    margin-left: 0;
    width: 210px;    
    margin-right: 20px;
  }

}

@media (max-width: 992px) {
  .topSection {
    padding: 0 12px;
  }

  .uploadContractButton, .saveDraftButton {
    width: 150px;    
    padding: 20px 10px;
  }

  h3.titleofthePage{
    font-size: 18px;
  }

  .adminManualInputContainer {
    display: block;
  }

  .changedriverIdtoHrIDContainer {
    display: flex;
  }

  .buttonContianers {
    margin: 20px 0;
  }

  .formSection {
    padding: 0 10px;
  }
}


@media (max-width: 767px) {
  .uploadContractButtonDisable{
    margin-left: 0;
  }

  .buttonTooltip {
    margin-left: 0;
  }
}
