.pendingpayment {
    text-align: center;
    margin: 15px  auto 0;
    min-height: 450px;
    width: 80%;
}
.pendingpayment img {
    max-height: 125px;
    min-height: 125px;
    margin: 20px 0 30px;
}
.historyContainerfull {
    width: 100%;
    margin: 2rem auto 1rem;
    cursor: pointer;
}
.historyContainerfull p {
    margin-bottom: 0;
    color: #fff;
    background:  rgb(128, 195, 67);
    padding: 10px 15px;
    font-weight: bold;
    cursor: pointer;
}