.view-applications {
    padding: 20px 40px 28px 32px;
    background: rgb(241 241 241);
}

.bread-1 {
    text-decoration: none;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    opacity: 0.3;
    color: black;
}

.ask-reschedule-link {
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: #80C343;
    font-weight: 600;
    text-decoration: underline;
}

.color-blue {
    color: blue;
}

.color-orange {
    color: orange
}

.color-green {
    color: #80C343
}

.color-purple {
    color: purple
}

.color-red {
    color: red
}

.checkCalender:disabled {
    cursor: not-allowed;
    background-color: #80c34399 !important;
}

.closeIcon {
    width: 100%;
    text-align: right;
}

.closeIcon img {
    width: 35px;
    height: 35px;
}

.selectReasonPopup .Component-paper-6 {
    width: 300px;
    height: 250px !important;
}

.popupHead {
    font-size: 24px;
    text-align: center;
    margin: 50px 0 0;
}

.popupHead span {
    font-size: 12px;
    display: block;
}

.reasonList {
    font-size: 24px;
    margin: 20px 0 50px;
}

.reasonList select {
    width: 100%;
    padding: 14px 2rem 14px 14px !important;
    outline: none;
    border-radius: 5px;
    margin-top: 5px;
    background: url("../../../assets/downarrow.png") no-repeat #fff;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    background-size: 14px;
    font-size: 14px;
    font-weight: 300;
    color: #777;
    border: 1px solid #777;
}


.drive-approval {
    margin-top: 42px;
    display: flex;
    justify-content: space-between;

}

.drive-approval .right-section {
    display: flex;
    align-items: center;
    gap: 24px;
}

.right-section img {
    cursor: pointer;
}

.driver-id {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
}

.driver-id span {
    font-weight: 600;
}

.driver-status {
    margin: 8px 4px 0 0;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}


.submit {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    color: #80C343;

}

.bg-check {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    color: #949494;

}

.pending {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    color: #E24C4B;
}


.normal-status {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    color: #000000;
}

.button-section {
    margin: auto 0;
}

.reject {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-radius: 8px;
    cursor: pointer;
    padding: 15px 40px 19px 40px;
    border: 1px solid #80C343;
}

.approve {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px 40px;
    background-color: #80C343;
    border: none;
    margin-left: 24px;
}

.upload {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px 40px;
    background-color: #80C343;
    border: none;
    margin-left: 24px;
}

.update-button-div {
    position: fixed;
    bottom: 20px;
    right: 40px;
}

.update-button {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px 40px;
    background-color: #80C343;
    border: none;
    margin-left: 24px;
}

.update-button-disable {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 8px;
    cursor: not-allowed;
    padding: 20px 40px;
    background-color: #80C343;
    opacity: 0.5;
    border: none;
    margin-left: 24px;
}

.view-certificates-section {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0 0;
}

.grp-btn-div {
    display: flex;
    gap: 16px;
}

.group-button {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    cursor: pointer;
    padding: 13px 26px;
    border: none;
    background-color: #FFFFFF !important;
}

.edit-group-button {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    cursor: pointer;
    padding: 13px 40px;
    border: none;
    background-color: #FFFFFF !important;
}

.documents {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    cursor: pointer;
    padding: 13px 26px;
    border: 1px solid #80C343;
    background-color: #FFFFFF;
    color: #80C343;
}

.details-section {
    margin: 32px 0 0 0;
    border-radius: 24px;
    border: 2px solid #FFFFFF;
    padding: 40px 40px 32px 40px;
}

.personal-details-and-edit-div {
    display: flex;
    justify-content: space-between;
}

.personal-details {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
}

.details-title {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 12px 0;
    opacity: 0.5;
}

.details-text {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 16px;
    margin: 0;
}

.space-between {
    display: flex;
    justify-content: space-between;

}

.vehicale-title {
    display: flex;
    gap: 39px;
}

.own-vehicale-details {
    display: flex;
    gap: 23px;
}

.own-vehicale {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -0.4px;
    margin: 7px 0;
    opacity: 0.5;
}

.own-vehicale-details .YES {
    font-weight: 500;
    color: #80C343;
    font-family: 'Lexend', sans-serif;
    font-size: 24px;
    letter-spacing: -0.4px;
    margin: 7px 0;

}

.own-vehicale-details .NO {
    font-weight: 500;
    color: black;
    font-family: 'Lexend', sans-serif;
    font-size: 24px;
    letter-spacing: -0.4px;
    margin: 7px 0;

}

.margin-24 {
    margin-top: 24px !important;
}

.margin-32 {
    margin-top: 32px !important;
}

.margin-47 {
    margin-top: 47px !important;
}

.number-color {
    color: #80C343;
}

.hr1 {
    margin-bottom: 0;
    background-color: black;
    height: 1px;
    opacity: 0.1;
}

.hr2 {
    margin-bottom: 0;
    background-color: black;
    height: 1px;
    opacity: 0.1;

}

.crossButtonContainer {
    float: left;
    cursor: pointer;
}

.titleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.selectTemplateText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    margin: 0px;
}

.uploadContractText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    margin: 0px;
    margin-bottom: 2em;

}

.ViewApplicationRadioGroup {
    display: flex;
    flex-direction: column;
    height: 16em;
    justify-content: space-around;
}

.formControlLabel {
    display: flex;
    align-items: center;
    padding: 1em;
    border: 1px solid #80C343;
    border-radius: 8px;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    opacity: 0.3;

}

.formControlLabelactive {
    display: flex;
    align-items: center;
    padding: 1em;
    border: 1px solid #80C343;
    border-radius: 8px;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
}

.radioButton {
    margin-right: auto;

}

.label {
    margin-left: auto;
}

.radioGroup .formControlLabel {
    margin-bottom: 10px;
}

.continueButtonContiner {
    display: flex;
    justify-content: center;
}

.continueButton {
    background: #F8F8F8;
    border: 1px solid #80C343;
    border-radius: 8px;
    padding: 1em 4em;
    cursor: pointer;
}

.anchor-btn {
    text-decoration: none;
    color: black
}

@media (max-width: 1338px) {
    .view-certificates-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 40px 0 0 0;
    }

    .grp-btn-div {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

}

@media screen and (max-width: 992px) {
    .view-applications {
        padding: 20px 20px 20px 20px;
    }

    .own-vehicale, .own-vehicale-details .NO {
        font-size: 18px;
    }

    .popupHead {
        margin-top: 20px;
    }

    .personal-details {
        font-size: 20px;
    }

    .driver-id {
        font-size: 16px;
        line-height: 20px;
    }
    
    .driver-status {
        font-size: 14px;
        line-height: 18px;
    }

    .view-certificates-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 40px 0 0 0;
    }

    .grp-btn-div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .approve {
        margin-left: 10px;
    }

    .details-section {
        padding: 15px 20px;
    }

    .details-text {
        margin-bottom: 15px;
        font-size: 15px;
    }

    .vehicale-title {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .update-button-div {

        bottom: 10px;
        right: 10px;
    }




}

/* edit view applications */
.view-applications select {
    width: 100%;
    padding: 14px;
    border: none;
    outline: none;
    /* background: #fff; */
    border-radius: 5px;
    background: url("downarrow.png") no-repeat #fff;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-size: 14px;
    font-family: 'Lexend',sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #777;
}

/* .personal-detail input { */
input[type="text"],
.emailId input[type="email"],
input[type="number"] {
    padding: 15px 15px;
    outline: none;
    margin-top: 0;
    /* border: 1px solid red; */
    border: none;
    border-radius: 5px;
    width: 100%;
    /* background: #fff; */
    cursor: text;
    background: #fff;
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #777;
}

.date {
    margin: 2px;
}

input[type="date"] {
    padding: 12px 10px;
    outline: none;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    width: 100%;
    background: #fff;
    cursor: pointer;
    /* background: url(".././assets/cb29241aae7495b231b40fa30ccb0b3be1ec67ff.png") no-repeat #fff;
      background-position: calc(100% - 0.75rem) center !important; */
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-size: 14px;
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    color: #777;
}


.personal-detail select {
    width: 100%;
    padding: 14px;
    border: none;
    outline: none;
    background: #fff;
    border-radius: 5px;
    /* padding-right: 15px; */
    margin-top: 5px;
    /* background: url(".././assets/downarrow.png") no-repeat #fff; */
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-size: 14px;
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #777;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
}

input[type="number"] {
    -moz-appearance: textfield;
}

textarea {
    padding: 12px 10px;
    outline: none;
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    resize: none;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 14px;
}



.ask_reschedule_box a {
    color: #80C343;
    padding-right: 10px;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.cancelAssignBtn {
    margin-right: 10px;
    border-radius: 8px !important;
    font-family: 'Lexend', sans-serif;
    width: 185px;
    height: 56px;
    border: 1px solid #80C343 !important;
    background-color: #ffffff !important;
}

.checkCalender {
    border-radius: 8px !important;
    width: 185px;
    height: 56px;
    background-color: #80C343 !important;
    border: 1px solid #80C343 !important;
    min-width: 185px !important;
    color: #fff !important;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
    background: white;
}

.select_manager_grid .MuiSelect-select:focus {
    background-color: transparent;
}

.selectDropDown svg.MuiSelect-icon {
    right: 14px;
}

.modal-reassign {
    min-height: 225px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px 30px 15px;
}

.modal-reassign h2 {
    font-family: "Roboto", sans-serif;
}

.confirm-text {
    color: #80C343;
    font-weight: bold;
    padding: 5px 0;
    font-size: 22px;
}

.id-desc {
    font-size: 20px;
}

.id-desc span {
    font-weight: 600;
    padding-right: 5px;
}

.ViewApplications-formControl-8 select.MuiNativeSelect-select {
    padding: 10px 15px;
    background: transparent !important;
}

.btnMobile {
    margin-left: 15px;
}

@media screen and (max-width: 767px) {
    .checkCalender {
        margin-top: 15px;
    }

    .btnMobile {
        margin-left: 0;
    }
}
