@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@100;300;400;500&display=swap');

body {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    background: #f7f7f7 !important;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Montserrat', sans-serif;
}

.mainSection h1 {
    font-size: 64px;
    line-height: 78px;
    font-weight: 600;
    margin-bottom: 38px;
    text-align: left;
    letter-spacing: 1px;
}

.mainSection .subHeading {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 80px;
    font-weight: 300;
}

.headerSection .commonButton,
.viewmorebtn .commonButton {
    background: #80C343 !important;
    width: 185px;
    height: 56px;
}

.fullBg {
    width: 100%;
    background: #fff;
}

.headerSection .commonButton:hover,
.viewmorebtn .commonButton:hover {
    box-shadow: none;
    background: #9a9a9a !important;
    border: 1px solid #9a9a9a;
}

.mainSection Button,
.headerSection .commonButton,
.viewmorebtn .commonButton {
    text-transform: none;
    color: #fff;
    font-size: 16px;
    line-height: 18px;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    box-shadow: none;
    margin: 0 40px 0 0;
    background: transparent;
    border: 1px solid #80C343;
}

.headerSection .mNone {
    margin: 0;
}

.container {
    margin: 0 auto;
    width: calc(100% - 60px);
    max-width: 1440px;
    padding: 0 15px;
    padding-left: 15px;
    padding-right: 15px
}

body .logoutMenu .menuItemlogout{
    width: 123px;
    height: 35px;
}

.iconsSections {
    margin: 64px 0 118px;
    text-align: center;
}

.btn-static-div {
    max-width: 800px;
    margin: 0 auto 20px;
}

.mt40 {
    margin-top: 40px;
}

.iconsSections .iconList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.iconsSections .iconList li {
    display: inline-grid;
    padding: 25px 70px;
    max-width: 375px;
    border-right: 1px solid #d1d1d1
}

.iconsSections .iconList li .iconSection {
    width: 150px;
    height: 150px;
    position: relative;
    display: inline-block;
    margin: 0 auto;
}

.iconsSections .iconList li .iconHead {
    font-family: 'Lexend', sans-serif;
    font-size: 20px;
    font-weight: 300;
}

.iconsSections .iconList li:last-child {
    border-right: 0;
}

.iconsSections .iconList li .iconSection img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 150px;
    height: auto;
}

.iconsSections h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 25px;
}

.mainSection .mainButton {
    border-radius: 5px;
    min-width: 185px;
    min-height: 56px;
    padding: 0 10px;
    background: #80C343;
    border: 1px solid #80C343;
}

.MuiDialogTitle-root {
    padding: 0px !important;
}

.mainBanner {
    background: linear-gradient(to right, #000, rgb(0, 0, 0, 0)), url("../../blocks/forgot-password/assets/global_assets/bgimg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 843px;
    color: #fff;
}

.innerBannerContent {
    margin: 0 auto;
    width: 1440px;
}

.innerContent {
    padding: 110px 0;
    max-width: 760px;
    text-align: left;
}

.aboutContentSection {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.p-10 {
    padding: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-4 {
    margin-right: 4px;
}

.left-top {
    transform-origin: left top;
}

.left-bottom {
    transform-origin: left bottom;
}

.MuiTypography-h6 {
    margin-top: -100px;
}

.StepperContainer-0-3-1 {
    padding: "0px !important";
}

.MuiInput-underline {
    text-decoration: none !important;
}

.footerSection {
    padding: 50px 0;
}

.footerSection .copyrightText p {
    margin-top: 30px !important;
}

.footerSection .followSection {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 20px;
}

.footerSection .MuiTypography-body1.officeAddress,
.footerSection .copyrightText .MuiTypography-body1 {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin-top: 30px;
}

.footerSection .MuiTypography-body1 a:hover {
    color: #80C343 !important;
}

.footerSection .MuiTypography-body1,
.footerSection .MuiTypography-body1 a {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
    text-decoration: none;
}

.MuiFilledInput-root {
    position: relative;
    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    /* background-color: none !important; */
    background-color: #F8F8F8 !important;
    border-radius: 5px;
    margin-top: 4px;
}

.MuiDialog-paperWidthSm {
    max-width: 680px !important;
    width: 680px !important;
}

.modal:after {
    content: ' ';
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 0px solid black;
    border-bottom: 15px solid transparent;
    right: 10px;
    top: 20px;
    margin-left: -10px;
}

.modal {
    border: 1px solid black;
    padding: 25px;
    background-color: white;
    margin-left: 69rem;
    margin-bottom: 32rem;
}

.headerSection {
    background: #fff !important;
    width: 100%;
}

.bgGrey .scrollContent {
    overflow-y: scroll;
    max-height: 460px;
    width: 720px;
    padding: 0 20px;
    margin: 0 auto 20px;
}

.bgGrey .scrollContent::-webkit-scrollbar {
    width: 6px;
}

.bgGrey .scrollContent::-webkit-scrollbar-track {
    background-color: #ECECEC;
    border-radius: 5px;
}

.bgGrey .scrollContent::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 5px;
}

.personal-detail .citizen-ship {
    width: 850px;
    max-width: 850px;
    margin: 0 auto;
}

.questionList {
    border-radius: 8px;
    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 24px;
    margin-bottom: 12px;
}

.questionList h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.questionList h5 span {
    padding-right: 10px;
}

.questionList .checkBoxcontent {
    border: 1px solid #e0f8d8;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    display: flex;
}

.questionList .checkBoxcontent.active {
    border: 1px solid #80C343;
}

.questionList .checkBoxcontent.active input[type="checkbox"] {
    border: 1px solid #80C343;
    background: #80C343;
}

.questionList .checkBoxcontent h6 {
    padding: 0;
    margin: 0 0 0 12px;
    font-size: 16px;
    font-weight: 300;
    font-family: 'Lexend', sans-serif;
    line-height: 16px;
}

.questionList .checkBoxcontent input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border: 1px solid #535050;
}

.questionList .checkBoxcontent p {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color: #000;
}

.headerSection .callUs {
    position: relative;
}

.headerSection .callUs .tootipText {
    background: #fff;
    width: 230px;
    height: 30px;
    visibility: visible;
}

.hide {
    background-color: #fff;
    padding: 30px;
    display: none;
    position: absolute;
    right: 60px;
    top: 0;
    min-width: 250px;
    height: auto;
    border-radius: 8px;
    -webkit-box-shadow: 0px 3px 18px -8px rgba(112, 108, 112, 0.63);
    -moz-box-shadow: 0px 3px 18px -8px rgba(112, 108, 112, 0.63);
    box-shadow: 0px 3px 18px -8px rgba(112, 108, 112, 0.63);
    font-size: 24px;
    color: #000;
    min-width: 255px
}


.hide:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #fff;
    top: 0;
    left: -30px;
}


.myDIV {
    position: relative;
    width: 50px;
    height: auto;
}

.myDIV:hover+.hide {
    display: block;
}

.callIcon {
    width: 24px;
    height: 24px;
    margin: 0 24px 0 0;
}

.headerTooltip {
    position: relative;
    bottom: 250px;
    font-size: 30px;
    text-align: center;
    visibility: hidden;
    height: auto;
    border-radius: 8px;
}

.text {
    font-size: 100px;
    border: 5px solid #0c0f0a;
    width: 400px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    cursor: pointer;
}

.headerTooltip:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 59px solid transparent;
    border-top: 52px solid white;
    top: 0;
    right: -39px;
    border-bottom: 30px solid transparent;
}

.breadcrumbsList .arrowRight {
    display: block;
    width: 14px;
    height: 14px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    transform: rotate(135deg);
}

.breadcrumbsList .MuiBreadcrumbs-separator {
    margin: 0 24px;
}

.breadcrumbsList .arrowRight path {
    display: none;
}

.breadcrumbsList a:hover {
    text-decoration: none;
    color: #000;
}

.date input::-webkit-inner-spin-button,
.date input::-webkit-clear-button {
    display: block;
}

.date input::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url("../../blocks/forgot-password/assets/global_assets/date-icon.png");
    background-size: auto auto;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.date input::-webkit-calendar-picker-indicator::after {
    content: '';
    display: block;
    background: transparent;
    width: 20px;
    height: 21px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: 0;
}

.date {
    position: relative;
    overflow: auto;
}

.contentSection {
    display: inline-flex;
    width: 100%;
}

.listData {
    width: 520px;
    padding: 100px 0 0 0;
}

.listData ul {
    margin: 0;
    padding: 0;
}

.oddContent {
    width: 40%;
    margin-right: 10%;
}

.contentArea {
    max-height: 180px;
    overflow: auto;
}

.earningContent .imgContainer {
    width: 53%;
    margin: 0;
}

.earningContent .imgContainer img {
    width: 80%;
}

.earningContent .listData {
    width: 40%;
    padding-left: 7%;
}

.earningContent .listData p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
}

.earningContent .listData p:last-child {
    font-size: 16px;
}

.termsCondition {
    font-size: 16px;
}

.innerSection {
    margin: 40px 0;
    float: left;
    width: 100%;
}

.innerImgcontent {
    margin: 40px 0;
}

.aboutContent {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
}

.delay h3 {
    margin-top: 0;
}

.about p{
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
}

.about h3 {
    font-size: 48px;
    line-height: auto;
    font-weight: 500;
    margin-bottom: 10px;
}

.innerImgcontent h3, .delay h3 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    margin-bottom: 10px;
}

.imgContainer.even {
    /* background-image: url("../../assets/reverseEllipse.png"); */
    padding-left: 12% !important;
    padding-right: 0 !important;

}

.text-typography {
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
}

.viewmorebtn {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.arrowClass {
    width: 30px;
    margin: 16px 12px 0 0;
}

.text-content {
    font-family: 'Lexend', sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    margin: 10px 10px 20px 10px;
}

.delay {
    background-image: linear-gradient(to left, #C3E3AE, #ffff);
    margin-top: 80px;
    font-family: 'Lexend', sans-serif;
}

.about {
    margin: 50px 0 !important;
    width: 100% !important;
    min-height: 800px;
}

.notDelay,
.about {
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    font-family: 'Lexend', sans-serif;
}

.contain {
    max-width: 70%;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.conatain-box {
    align-items: center;
}

.conatain-box h5 {
    font-family: 'Montserrat', sans-serif; 
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 49px;
}

.conatain-box p {
    font-family: 'Lexend', sans-serif; 
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
}

.earnTypo {
    font-family: 'Lexend', sans-serif;
    font-style: 300;
    font-size: 24px;
    line-height: 38px;
}

.about .aboutImg {
    min-width: 50%;
    display: block;
    position: relative;
    margin-top: -80px;
}

.about .aboutImg img {
    width: 500px;
    display: block;
    height: auto;
    margin-left: 50px;
    margin-top: 30px;
}

.about .aboutImg span {
    width: 510px;
    height: 590px;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    margin: 0 auto;
    z-index: -1;
    top: 0;
}

.about .aboutImg span.aboutImgspan {
    z-index: 4;
    left: 0px;
    top: -30px;
}

.about .aboutImg span.first {
    transform: translate(5%, 5%);
    background: #9A9A9A;
}

.about .aboutImg span.second {
    transform: translate(14%, -5%);
    background: #D3F5B0;
}

.happysafe {
    padding: 50px 0;
    background-color: #E6F3D9;
}

.comma {
    color: #80C343;
    position: absolute;
    font-size: 60px;
    width: 25px;
}

.comma.first {
    left: 190px;
    top: 38px;
}

.comma.last {
    right: 475px;
    bottom: 18px;
}

.happytext {
    text-align: center;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    display: inline-block;
    padding: 0 132px;
    position: relative;
    margin-bottom: 0;
}

.listData ul li {
    list-style: none;
    background: url("../assets/arrow-icon.png") no-repeat left 6px;
    padding: 0 0 0 50px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    font-family: 'Lexend', sans-serif;
    margin: 0 0 10px;
}

.listData h3 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    margin-bottom: 10px;
}

.imgContainer {
    background-image: url("../assets/download-bg.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 520px;
    width: 714px;
    margin-right: 200px;
}

.imgContainer img {
    border-radius: 0 70px 70px 0;
    margin: 20px 0 0 0;
}


.bgCheck .imgContainer.bgRightimg {
    padding-right: 5%;
}

.bgCheck .imgContainer .bgleftimg {
    width: 90%;
    margin-top: 80px;
}

.imgContainer.odd {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    padding-right: 12%;
    background-image: url("../assets/download-bg.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 573px;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
}

.imgContainer.odd img {
    border-radius: 70px 0 0 70px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin: 60px 0 0 0;
}

.imgContainer.bgRightimg img {
    max-width: 100%;
    margin-top: 92px;
}


.bgImageleft {
    height:auto;
    width: 500px;
    max-height:auto;
    max-width: 500px;
    border-radius: 0 15% 15% 0;
 }


 .bgCheckTitle {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    margin: 10px;
    color: #000;
    font-family: 'Lexend', sans-serif;
}

.mainTitlebgCheck {
    margin: 50px 0 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
}

.marginLeftArrow {
    margin-right: 170px;
}

.bgHead {
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Montserrat';
    font-size: 40px;
}


@media screen and (max-width: 1500px) {
    .container {
        width: 1400px;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .personal-detail .daf {
        display: block;
    }

    .iconsSections .iconList li {
        max-width: 275px;
        padding: 25px 30px;
    }

    .about .aboutImg {
        min-width: 40%;
        margin-top: 0;
    }

    .about .aboutImg img {
        width: 300px;
        margin-left: 57px;
    }

    .innerContent {
        padding: 80px 40px;
    }

    .imgContainer.odd {
        height: auto;
        padding-right: 0;
    }

    .about .aboutImg span {
        width: 320px;
        height: 390px;
    }

    .about h3 {
        margin: 0;
    }

    .about {
        min-height: 368px;
    }

    .bgCheck .imgContainer {
        height: auto;
    }
}

@media screen and (max-width: 1366px) {
    .container {
        width: 100%;
    }

    .bgHead {
        margin-bottom: 10px;
        font-weight: bold;
        font-family: 'Montserrat';
        font-size: 20px;
    }

    .marginLeftArrow {
        margin-right: 40px;
    }

    .personal-detail .citizen-ship {
        width: 100%;
        max-width: 100%;
    }

    .btn-static-div {
        max-width: 100%;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .bgGrey .scrollContent {
        width: 100%;
    }

    .aboutContentSection {
        width: 50%;
        margin-left: 10%;
    }

    .imgContainer {
        width: 50%;
        padding-right: 0%;
        margin: 0;
    }

    .imgContainer img {
        max-width: 90%;
        margin: 50px 0;
    }

    .listData {
        width: 50%;
        padding: 0 2% 0 3%;
    }

    .innerBannerContent {
        width: 100%;
    }

    .listData h3 {
        font-size: 30px;
    }

    .happytext {
        padding: 0 10px;
    }

    .mainSection h1 {
        font-size: 34px;
    }
}


@media screen and (max-width: 992px) { 
    .headerSection {
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 768px) { 
    .mainBanner {
        min-height: 400px;
    }

    .imgContainer.bgRightimg img {
        margin-top: 0;
    }

    .scrollContent .df {
        display: block;
        margin: 10px 0;
    }

    .scrollContent .df .div-checkbox {
        margin: 10px 0 !important;
    }

    .loginModalContainer {
        padding: 20px;
    }

    .driverheading {
        font-size: 24px;
    }

    .bgImageleft {
        height:auto;
        width: 275px;
        max-height:auto;
        max-width: 275px;
        border-radius: 0 15% 15% 0;
     }

    .contentSection{
        display: block;
    }

    .imgContainer {
        width: 100%;
    }

    .listData {
        width: 100%;
        padding: 0;
    }

    .imgContainer.odd {
        width: 100%;
    }

    .earningContent .imgContainer {
        width: 100%;
    }

    .earningContent .listData {
        width: 100%;
        padding: 0;
    }

    .notDelay {
        display: block;
    }

    .notDelay .contain {
        max-width: 100%;
    }

    .innerImgcontent h3, .delay h3 {
        font-size: 25px;
    }

    .aboutContent {
        font-size: 15px;
        line-height: 30px;
    }

    .about p {
        font-size: 16px;
        line-height: 28px;
    }

    .happytext {
        font-size: 18px;
        line-height: 30px;        
    }

    .about .flex {
        display: block;
    }

    .aboutImg {
        height: 450px;
    }

    .listData h3 {
        font-size: 22px;
        line-height: 32px;
    }

    .viewmorebtn {
        width: 100%;
        display: block;
    }

    .earningContent .listData p, .listData ul li {
        font-size: 16px;
    }

    .aboutContentSection {
        width: 100%;
        margin-left: 0;
    }

    .about .aboutImg span.second {
        transform: translate(0, 0%);
    }

    .about .aboutImg span.first {
        display: none;
    }

    .about .aboutImg img {
        margin-left: 10px;
        margin-top: 44px;
    }
}


@media screen and (max-width: 600px) {
    .mainSection h1 {
        font-size: 20px;
        line-height: 32px;
    }

    .imgContainer img {
        max-width: 75%;
    }

    .headerSection .commonButton, .viewmorebtn .commonButton {
        width: 100px;
    }

    .mainSection .subHeading {
        font-size: 16px;
        line-height: 26px;
    }

    .mainSection .mainButton {
        margin-bottom: 10px;
    }

    .iconsSections .iconList li {
        border: 0;
    }

    .iconsSections {
        margin: 20px 0 0;
    }

    .imgContainer {
        height: 400px;
    }

    .aboutContentSection h3 {
        font-size: 24px;
    }

    .btn-static-div {
        padding: 0 5px;
    }

    .btn-transparent {
        padding: 10px 15px;
    }

    .listData {
        margin-top: 20px;
    }
}

@media(max-width: 585px) {
    .mobile-logo {
        width: 100px;
    }

    .mobile-logo .img1 {
        width: 30px !important;
        height: auto !important;
    }

    .mobile-logo .img2 {
        width: 70px !important;
        height: auto !important;
    }
}

@media(max-width: 480px) {
    .avatar {
    width: 40px !important;
    }
    .avatarlogo {
        width: 100px !important;
    }
    }