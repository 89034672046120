.previous-week-box {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.available-right {
    margin-left: auto;
    display: flex;
    align-items: center;
    top: 30%;
    grid-gap: 20px;
    gap: 20px;
    position: absolute;
    right: 37px;
}

.available-sub {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
}

.greenbg {
    width: 30px;
    height: 15px;
    background-color: #52d546;
    border-radius: 4px;
}

.graybg {
    width: 30px;
    height: 15px;
    background-color: #8d8b8b;
    border-radius: 4px;
}

.previous-week {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;
}

.customized-dialog-week .MuiPaper-root {
    background-color: #eceaea;
}

.customized-dialog-week .MuiTableContainer-root {
    background-color: #fff;
}

.customized-dialog-week table {
    width: 100%;
}

.customized-dialog-week table thead tr th,
.customized-dialog-week table tbody tr td {
    border-right: solid 1px rgba(224, 224, 224, 1);
}

.customized-dialog-week table thead tr th {
    text-align: center;
    border-top: solid 1px rgba(224, 224, 224, 1);
}

.dayOfWeek {
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
}

.dateOfWeek {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
}

.customized-dialog-week table thead tr th h6 {
    font-size: 18px;
    line-height: 25px;
}

.green-day button {
    background-color: #80C343;
    border: none;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    height: 44px;
}

.green-day button:disabled {
    background-color: #949494 !important;
    color: #fff;
}

.green-day button:hover {
    background-color: #17750f;
    border: none;
}

@media (max-width: 992px) {
    .available-right {
        position: static;
        margin-right: auto;
    }

    .previous-week-box {
        flex-wrap: wrap;
        align-items: center;
    }
}