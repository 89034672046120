@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");

.App {
  text-align: center;
  min-height: 100vh;
  display: block;
  background: #F2F2F2;
  padding: 45px;
}

.App .cell {
  display: flex;
  justify-content: space-between;
}

.header {
  margin-bottom: 40px !important;
}

.header .date {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  text-align: left;
  margin-bottom: 5px;
}

.header .time {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #949494;
  margin: 0px;
  text-align: left;
}

.header .day {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.available-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  display: flex;
  align-items: center;
}

.available-text:first-child {
  margin-right: 25px;
}

.selectdate {
  width: 200px;
}

.right-colm {
  display: flex;
}

.right-colm .available-text::before {
  width: 36px;
  height: 16px;
  display: block;
  content: '';
  margin-right: 10px;
}

.right-colm .available-text:first-child::before {
  background: #80C343;
  border-radius: 4px;
}

.right-colm .available-text:nth-child(2)::before {
  background: #949494;
  border-radius: 4px;
}

.cell {
  padding: 8px 24px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  align-items: center;
}

.cell .date,
.cell p {
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.cell .RadioGroup {
  display: flex !important;
  flex-direction: row !important;
}

.cell .RadioGroup .MuiFormControlLabel-root {
  background: #FFFFFF;
  border: 1px solid rgba(128, 195, 67, 0.203234);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 15px 15px;
  /* color: #949494; */
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 24px !important;
  min-width: 105px;
  letter-spacing: -0.3264px;
  color: rgba(0, 0, 0, 0.5);
}

.cell .RadioGroup .MuiFormControlLabel-root.uncheckunavailability, .cell .RadioGroup .MuiFormControlLabel-root.uncheckavailability{
  border-color: #80C343;
  color: #000;
}
.react-calendar__tile--now {
  background: rgba(128, 195, 67, 0.5);
}

.cell .uncheckavailability {
  opacity: 1 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App.myHeader {

  position: relative;
}
#nav-months {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px) !important;
  z-index: 9999;
  left: 25px;
  top: 45%;
}
#nav-months .nav-month-btn {
  padding: 15px 19px;
    border: 0px;
    border-radius: 100%;
}
.App-link {
  color: #61dafb;
}


@media screen and (max-width: 767px) {
  .App {
    padding: 45px 20px;
  }  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}