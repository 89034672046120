
.text-font {
    font-family: 'Lexend', sans-serif;
}

.title-font {
    font-family: 'Montserrat', sans-serif;
}

.driver-application {
    padding-left: 40px;
}

.last-check .date {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;

}

.last-check .time {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #949494;
    margin: 4px 0 0 0;
}

.Outstanding {
    margin-top: 40px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.applications-table {
    margin: 20px 80px 0 0;
    border: 2px solid #FFFFFF;
    border-radius: 24px;
    padding: 32px 24px 32px 24px;
}

.view-app-button {
    border: none;
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #80C343;
    padding: 13px 24px;
    background: white;
    border-radius: 8px;
    cursor: pointer;
}

.color-blue {
    color: blue;
}

.color-orange {
    color: orange
}

.color-green {
    color: #80C343
}

.color-red {
    color: red
}

.color-purple {
    color: purple
}

.bg-check::first-letter {
    text-transform: uppercase
}

.submit::first-letter {
    text-transform: uppercase
}

.pending::first-letter {
    text-transform: uppercase
}

/* Override mui css */
.driver-application .MuiTableCell-root {
    border-bottom: 2px solid #FFFFFF;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 16px 8px;
}

.driver-application .MuiTableCell-head {
    font-family: 'Lexend', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.pagination {
    margin: 20px 80px 20px 0;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 992px) {
    .driver-application {
        padding-left: 20px;
        padding-top: 20px;
    }

    .driver-application .MuiTableCell-head {
        font-size: 14px;
        line-height: 18px;
    }

    .driver-application .MuiTableCell-root {
        font-size: 14px;
        line-height: 18px;
    }

    .Outstanding {
        margin-top: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    

    .applications-table {
        margin: 20px 20px 0 0;
        padding: 15px;
    }

    .pagination {
        margin: 20px 20px 20px 0;

    }
}
