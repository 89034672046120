.sidebar {
    width: 342px;
    min-height: calc(100vh - 127px);
    height: 100%;
    background: #FFFFFF;
}

.sidebar ul {
    list-style-type: none;
    padding: 40px 0 0 0;
    margin: 0;
}

.sidebar li {
    padding: 25px 68px 25px 80px;
}

.sidebar a {
    line-height: 20px;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #949494;
    display: flex;
    text-decoration: none;
    gap: 10px;
}

.sidebar .icons {
    height: 20px;
    width: 17px;
}

.sidebar .active {
    background-color: #80C343;

}

.active a {
    color: #FFFFFF;
    font-weight: 700;
}

.margin-0 {
    margin: 0;
}

.menuIcon {
    display: none;
}


.menu {
    background: #34495e;
    height: 100vh;
    width: 250px;
    opacity:0; 
    position: absolute;
    visibility: hidden;
    transition: all 0.25s ease;
    transform: translateX(-50%);
}


.active {
    opacity:1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
  }

  .mobileMenu {
    display: none;
  }

@media screen and (max-width: 992px) {

    .avatar {
        margin-left: 40px;
    }

    .desktopMenu {
        display: none;
    }

    .mobileMenu {
        display: block;
        position: absolute;
        z-index: 10000;
    }

    .sidebar ul {
        padding: 0;
    }
    
    .sidebar li {
        padding: 25px 40px;
    }


    .menuIcon {
        display: block;
        font-size: 0;
        z-index: 99999;
        border: 1px solid #fff;
        background-color: #666;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin: 0;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10px;
        left: 10px;
    }
    
    .menuIcon::before, .menuIcon::after {
        content: "";
        background-color: #fff;
        height: 2px;
        width: 1rem;
        position: absolute;
        transition: all 0.3s ease 0s;
        left: 12px;
    }
    
    .menuIcon::before {
        top: 22px;
    }
    
    .menuIcon::after {
        top: 14px;
    }    
}
