.toggleCalender .react-calendar__navigation__arrow {
    /* pointer-events: none;
    display: none; */
}

.toggleCalender .react-calendar__navigation__label {
    padding: 10px;
    background: #80C343 !important;
    border-radius: 5px;
    font-weight: 700;
    max-width: max-content;
}
.toggleCalender .react-calendar__navigation__label:hover {
    background: #4fd647;
}

.toggleCalender .react-calendar__tile {
    height: 55px;
    padding: 0px !important;
}

.toggleCalender .button.react-calendar__tile.react-calendar__decade-view__years__year {
    border-radius: 4px !important;
    padding: 0 !important;
}
.react-calendar__tile--hasActive {
    background: #80C343;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.toggleCalender .react-calendar__tile:hover {
    background: #e3ffca;
    color: #111;
}

.toggleCalender .react-calendar__tile--active {
    background: #80C343;
    color: #111;
    border-radius: 100%;
}

.toggleCalender .react-calendar__tile--active:hover {
    background: #80C343;
}

.toggleCalender .react-calendar {
    padding: 15px !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    width: 400px !important;
    background: #FFFFFF;
    border-radius: 8px;
    border: 0;
    position: absolute;
    z-index: 1004;
    margin: 10px 49px;
    margin-top: 0px;
}


.toggleCalender .react-calendar__month-view__days__day--weekend {
    color: #000;
}


.toggleCalender .react-calendar__tile--now {
    background: #80C343;
}
.toggleCalender .react-calendar__year-view__months .react-calendar__tile:hover {
    background: #e3ffca !important;
    border-radius: 10px !important;
}

.toggleCalender .react-calendar__year-view__months .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 10px !important;
    width: 90%;

}

.mainCalendar #nav-months {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 50px) !important;
    z-index: 9999;
    left: 25px;
    top: 45%;
}

.mainCalendar #nav-months button {
    padding: 15px 19px;
    border: 0px;
    border-radius: 100%;
    cursor: pointer;
}

.mainCalendar .react-calendar {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: none;
    background: transparent;
    margin-bottom: 40px;
}

.mainCalendar {
    padding: 0px 47px 15px 47px !important;
    position: relative;
    /* width: calc(100% - 30px) !important; */
}

.change-date {
    padding: 0 30px;
    margin: 5px 0 !important;
}


@media screen and (max-width: 992px) {
    .mainCalendar {
        padding: 0px 15px 15px 15px !important;
        width: calc(100% - 50px) !important;
    }
    .mainCalendar #nav-months {
        width: calc(100% - 8px) !important;
        left: 4px;
    }

    .toggleCalender .react-calendar {
        margin: 10px 5px;
    }
}

@media screen and (max-width: 767px) {
    .toggleCalender .react-calendar {
        width: 96% !important;
    }

    .mainCalendar {
        padding: 0px !important;
        width: 98% !important;
        margin: 0 auto !important;
    }

}
