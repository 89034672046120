.driver-main-conatiner {

    padding: 40px 80px 0 100px;
}

.green-slot-btn {
    margin: 10px auto 0 auto;
    width: 132px;
    background: #80c343;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    color: #f8f8f8;
    border: none;
    cursor: pointer;
}

.green-slot-btn p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
}

.grey-slot-btn p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
}

.grey-slot-btn {
    margin: 10px auto 0 auto;
    width: 132px;
    background: #949494;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    color: #000000;
    border: none;
    cursor: pointer;
    opacity: 0.5;
}

.month-select-btn {
    border: none;
    outline: none;
    background-color: inherit;
    font-size: 24px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 29.26px;
    margin-bottom: 8px;
}

.month-select-btn img {
    margin-left: 8px;
}

.select_induction_slot {
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    line-height: 19.5px;
}

.text-font {
    font-family: 'Lexend', sans-serif;
}

.date-and-today-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.current-date-section {
    display: flex;
    align-items: center;
}

.last-check .date {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;

}

.last-check .time {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #949494;
    margin: 4px 0 0 0;
}

.backIcon {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-right: 10px;
}

.todayBtn {
    width: 185px;
    height: 56px;
    background: #ffffff;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: none;
}

.custom-tootlbar-all-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.toolbar-buttons {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
}

.available {
    width: 36px;
    height: 16px;
    background-color: #80C343;
    border-radius: 4px;
    margin-right: 8px;
}

.unavailable {
    width: 36px;
    height: 16px;
    background-color: #949494;
    border-radius: 4px;
    margin-right: 8px;
}

.indicate-slot {
    display: flex;
    gap: 24px;
}

.indicate-slot-insider {
    display: flex;
    align-items: center;
}

.indicate-slot-insider p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    size: 16px;
    line-height: 19.5px;
    margin: 0;
}

.toolbar-buttons img {
    cursor: pointer;
}

.toolbar-label {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    size: 20px;
    line-height: 24.38px;
}

.full-container {
    width: 300px;
    height: 110px;
    padding: 25px 24px 16px 55px;
}

.popperContainer {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.green-circle {
    height: 16px;
    width: 16px;
    background: #80c343;
    border-radius: 10px;
}

.popper-slotLabel {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-left: 5px;
}

.book-slot-blackBtn {
    background: #80c343;
    border-radius: 4px;
    height: 32px;
    width: 190px;
    border: none;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

/* custom lib */
.rbc-time-view .rbc-allday-cell {
    display: none;
}

.rbc-time-content>.rbc-time-gutter {
    display: none;
}

.rbc-time-view {
    background: #FFFFFF;
    padding: 24px;
    border: none;
    border-radius: 8px;
}

.rbc-label {
    padding: 0;
}

.rbc-time-view .rbc-row {
    min-height: 60px;
}

.rbc-event-label {
    display: none;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    min-height: 40px;
    border: none;
    background-color: #FFFFFF;
    padding: 0;
}

.rbc-day-slot .rbc-time-slot {
    border: none;
    min-height: 60px;
    margin: 0;
}

.rbc-time-content>*+*>* {
    border-bottom: none;
}

.rbc-today {
    background-color: #FFFFFF;
}

.rbc-day-slot .rbc-events-container {
    margin-right: 0;
}

.rbc-time-content {
    border-top: none;
}

.rbc-header {
    border-bottom: none;
}

.slotMonthCalendar .react-calendar {
    position: absolute;
    z-index: 1004;
    padding: 15px !important;
    border: none !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    font-family: "Montserrat", sans-serif !important;
    margin-top: 15px;
}

.slotMonthCalendar .react-calendar__navigation__label {
    background: #80C343 !important;
    border-radius: 6px;

}

.slotMonthCalendar .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #e6e6e6;
    color: #000000;

}

.slotMonthCalendar .react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #e6e6e6;
}

.slotMonthCalendar .react-calendar__tile--active {
    color: #000000;
}

.slotMonthCalendar .react-calendar__navigation__label span {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
    font-family: "Montserrat", sans-serif !important;
}

.slotMonthCalendar .react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    font-weight: 500;
    line-height: 19.5px;
    font-family: "Montserrat", sans-serif !important;
}

.slotMonthCalendar .react-calendar__navigation button {
    font-size: 25px;
    font-weight: 500;
}

@media screen and (max-width: 1220px) {

    .driver-main-conatiner {
        padding: 20px;
        }
    }
    
    @media screen and (max-width: 992px) {
        .driver-main-conatiner .rbc-time-view {
            width: auto;
            overflow: scroll;
        }


        .driver-main-conatiner .rbc-time-header, .driver-main-conatiner .rbc-time-content {
            width: 1024px;
        }
    }

    @media screen and (max-width: 600px) {
        .rbc-time-view {
            padding: 10px;
        }
    
    .custom-tootlbar-all-group {
        flex-direction: column;
        gap: 15px;
    }
    
    .indicate-slot {
        margin-top: 10px;
    }
    
    .slotMonthCalendar .react-calendar {
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
    } 