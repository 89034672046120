.viewCertificate .MuiBreadcrumbs-separator img:first-child {
    opacity: 0.3;
}

.drive-approval {
    display: flex;
    justify-content: space-between;

}

.msgDoc {
    font-size: 14px;
    color: #666;
}

.requireDoclist {
    text-align: center;
    margin: 20px 0;
}

.requireDoclist select {
    width: 70%;
    margin: 0 auto;
}

.requireDoclist select option {
    padding: 10px !important;
}

.driver-id {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
}

.driver-id span {
    font-weight: 600;
}

.details-textnoDoc {
    width: 100%;
    text-align: center;
    padding: 50px;
    font-size: 15px;
    font-weight: 500;
    border-top: 1px solid #D1D1D1;
    border-bottom: 1px solid #D1D1D1;
    margin: 30px;
}

.driver-status {
    margin: 8px 0 0 0;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}

.color-blue {
    color: blue;
}

.color-orange {
    color: orange
}

.color-green {
    color: #80C343
}

.color-purple {
    color: purple
}

.color-red {
    color: red
}

.button-section {
    margin: auto 0;
    display: flex;
    gap: 16px;
}

.view-app-button {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    cursor: pointer;
    padding: 13px 66px;
    border: 1px solid #80C343;
    background-color: #FFFFFF;
    color: #80C343;
}

.group-button {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    border-radius: 8px;
    cursor: pointer;
    padding: 13px 26px;
    border: none;
    background-color: #FFFFFF;

}

.certificateAndDocument-div {
    margin-left: 45px;
}

.certificateAndDocument {
    margin: 46px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.certificateAndDocument p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 5px;
}

.doc-download-button {
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    padding: 22px 20px 22px 16px;
    border: none;
    background-color: #FFFFFF;
    margin: 10px 0;
    width: calc(100% - 20px);
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

.doc-download-button div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.doc-download-button p {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    width: 203px;
    margin: 0px 16px;
    text-align: left;
}

.margin-48 {
    margin: 48px 0 0 0;
}

.margin-31 {

    margin: 31px 0 0 0;
}

.hr {
    margin-bottom: 0;
    background-color: black;
    height: 1px;
    opacity: 0.1;
}

.View-All {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-radius: 8px;
    cursor: pointer;
    padding: 15px 40px 19px 40px;
    border: 1px solid #80C343;
}

.Download-All {
    font-family: 'Lexend', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px 40px;
    background-color: #80C343;
    border: none;
    margin-left: 24px;
}

.view-download-btn-div {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
}

.viewCertificate {
    padding: 30px 30px 0;
    background: #f1f1f1;
    min-height: calc(100vh - 127px);
    width: 100%;
}

.historyContainerfull {
    width: 35%;
    margin: 2rem auto 1rem;
    cursor: pointer;
}

.historyContainerfull p {
    margin-bottom: 0;
    color: #fff;
    background: rgb(128, 195, 67);
    padding: 10px 15px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1280px) {

    .doc-download-button {
        margin-bottom: 15px;
    }


}

@media screen and (max-width: 992px) {
    .drive-approval {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .viewCertificate {
        padding: 30px 10px 0;
    }

    .certificateAndDocument p {
        font-size: 16px;
    }

    .button-section {
        margin: auto 0;
        gap: 16px;
        flex-wrap: wrap;
    }

    .certificateAndDocument-div {
        margin-left: 0;
    }

    .certificateAndDocument {
        gap: 20px;
        flex-wrap: wrap;

    }

    .doc-download-button {
        margin-bottom: 15px;
    }

}


@media screen and (max-width: 767px) {
    .downloadBox {
        padding: 22px 20px 22px 16px;
        background-color: #FFFFFF;
        border-radius: 8px;
        margin-top: 15px;
    }


    .historyContainerfull {
        width: 100%;
    }

    .doc-download-button {
        margin: 0px;
        padding: 0;
        background-color: transparent;
    }

    .doc-padding-0 {
        padding-bottom: 0;
    }

    .doc-padding-0 .MuiGrid-root.MuiGrid-item {
        padding-bottom: 0 !important;
    }
}