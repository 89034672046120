.bgheading {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;

    position: absolute;
    left: 50%;
    top: -7px;
    transform: translate(-50%, 0);
    white-space: nowrap;
}

.bgTitleheading {
    /* display: flex;
    align-items: center; */
    display: inline-block;
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
}

.bgcontent {
    font-size: 15px;
    line-height: 24px;
    margin-top: 30px;
    min-height: 400px;
}


.closeArrowStyle {
    margin-left: 0;
}

.bgbutton {
    position: relative;
    bottom: 15px;
}

.payement-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px
}

.font-bold {
    font-weight: bold;
}

.addressconf {
    border: 1px solid #cccc;
    border-radius: 5px;
    padding: 8px 15px;
}

input[type="text"],
.emailId input[type="email"],
input[type="number"] {
    color: #000;
    font-size: 16px;
}

.redSign {
    padding-left: 5px;
}

.StripeElement {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
}

.InputElement {
    font-size: 16px;
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
}

.widthFull {
    width: 100%;
}

@media (max-width: 1200px) {
    /* .bgTitleheading {
        margin-top: 40px;
    } */
    
    .bgTitleheading .bgheading {
        font-size: 20px;
    }

    .mrTitle {
        /* margin-right: 30%; */
    }
}

@media (max-width: 480px) {
    .bgGrey .MuiStep-horizontal {
        padding-left: 4px;
        padding-right: 4px;
    }
}

@media (max-width: 767px) {
    .yesPayment{
        margin-bottom: 10px;
    }
}