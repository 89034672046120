/* * {
  font-family: "Lexend-Light";
} */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");
*/
/* @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&family=Montserrat:wght@300&display=swap"); */

body {
  /* font-family: "Roboto", sans-serif;
  font-family: "Noto Sans", sans-serif;
  background: #f7f7f7; */
  overflow-x: hidden;
}
.delete-work-exp{
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.bgGrey {
  background: #f7f7f7;
}

.colorGrey {
  color: #a7a7a7;
}

.colorBlack {
  color: #000;
}

.personal-detail h1 {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  margin-top: 0;
}

.fs16 {
  font-size: 16px;
}

.fs20 {
  font-size: 20px;
}

.fs22 {
  font-size: 22px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.personal-detail sup {
  font-size: 14px;
  color: red;
}

.questionBankHeading h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.questionBankHeading p {
  font-weight: 400;
  font-size: 15px;
}

.bgheading {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;

  position: absolute;
  left: 50%;
  top: -7px;
  transform: translate(-50%, 0);
  white-space: nowrap;
}

.bgTitleheading {
  /* display: flex;
  align-items: center; */
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
}

.bgcontent {
  font-size: 15px;
  line-height: 24px;
  margin-top: 30px;
  min-height: 400px;
}


.closeArrowStyle {
  margin-left: 0;
}

.bgcheckTitle {
  max-width: 580px;
  margin: 0 auto;
}

.bgcheckTitle .bgheading{
  padding-left: 170px;
}

.multiplecheckTitle {
  max-width: 675px;
  margin: 0 auto;
}

/* .personal-detail input { */
input[type="text"],
.emailId input[type="email"],
input[type="number"] {
  padding: 15px 15px;
  outline: none;
  margin-top: 0;
  /* border: 1px solid red; */
  border: none;
  border-radius: 5px;
  width: 100%;
  /* background: #fff; */
  cursor: text;
  background: #fff;
  font-family: 'Lexend';
  font-size: 14px;
  font-weight: 300;
  color: #777;
}

.date {
  margin: 2px;
}

input[type="date"] {
  padding: 12px 10px;
  outline: none;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  cursor: pointer;
  /* background: url(".././assets/cb29241aae7495b231b40fa30ccb0b3be1ec67ff.png") no-repeat #fff;
  background-position: calc(100% - 0.75rem) center !important; */
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  background-size: 14px;
  font-family: 'Lexend';
  font-size: 14px;
  color: #777;
}


.personal-detail select {
  width: 100%;
  padding: 14px;
  border: none;
  outline: none;
  background: #fff;
  border-radius: 5px;
  padding-right: 15px;
  margin-top: 5px;
  background: url(".././assets/downarrow.png") no-repeat #fff;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  background-size: 14px;
  font-family: 'Lexend';
  font-size: 14px;
  font-weight: 300;
  color: #777;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  padding: 12px 10px;
  outline: none;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  resize: none;
  font-family: 'Lexend';
  font-weight: 300;
  font-size: 14px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.pt40 {
  padding-top: 40px;
}

.mr7 {
  margin-right: 7px;
}

.mr15 {
  margin-right: 15px;
}

.ml56 {
  margin-left: 56px;
}

.ml10 {
  margin-left: 10px;
}

.mt0 {
  margin-top: 0px;
}

.mt10 {
  margin-top: 10px;
}

._mt10 {
  margin-top: -10px;
}

.btn-transparent {
  padding: 10px 25px;
  background: #fff;
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0,0.25); */
  cursor: pointer;


}

.btn-green {
  padding: 10px 5px 10px 20px;
  background: rgb(128, 195, 67);
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  opacity: 0.5;
  font-family: 'Lexend';
  cursor: pointer;

}

.btn-green-full-opacity {
  padding: 10px 5px 10px 20px;
  background: rgb(128, 195, 67);
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;


}

.da {
  display: flex;
  align-items: center;
}

.da img {
  height: auto;
  width: 20px;
  padding-top: 5px;
}

/* .btn-green img {
  height: 12px;
  rotate: 180deg;
  padding-right: 40px;
} */

/* Input checkbox CSS  */
input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  height: 16px;
  width: 16px;
  border: 2px solid #c4b4b4;
  border-radius: 4px;
  margin: 0;
}

input[type="checkbox"]:checked {
  background: rgb(128, 195, 67);
  border-color: rgb(128, 195, 67);

}

input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 42%;
  top: 16%;
  width: 24%;
  height: 55%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

/* Input checkbox CSS  */

.div-checkbox {
  display: flex;
  align-items: center;
  border: 1px solid #bdc7bd;
  padding: 13px;
  border-radius: 8px;
  background: #fff;
}

.df {
  display: flex;
  align-items: center;
}

.daf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div-checkbox label {
  padding-left: 10px !important;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.redSign {
  color: red;
}

.w-100 {
  width: 100%;
}

.err {
  margin: 5px 0 0 10px;
  color: red;
  font-size: 14px !important;
}

.work-expn label {
  font-size: 15px;
  color: #929292;
}

.regis label {
  color: #000;
  margin-right: 10px;
}

.regis p {
  font-size: 15px;
  margin: 0 10px 0 0;
}

.regis input[type="radio"] {
  margin-right: 7px;
}

/* Radio Button CSS  */
input[type="radio"] {
  accent-color: rgb(128, 195, 67);
}

input[type="radio"]:checked:before {
  content: "";
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(128, 195, 67);
}

/* Radio Button CSS  */

/* Multi Step CSS  */
.multi-step ol {
  justify-content: center;
}

#RFS-StepButton {
  box-shadow: 1px 1px 10px rgb(128, 195, 67);
}

/* Multi Step CSS  */

/* Header CSS  */
.custom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 90px 0px 90px;
  margin-bottom: 15px;
}

.call-div {
  display: flex;
  align-items: center;
}

.call-div p {
  margin: 0 !important;
}

.custom-header .call-image {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

/* Header CSS  */

.dFlex {
  display: flex;
  background: #fff;
  border-radius: 5px;
}

/* Mobile Tag CSS  */
.aus-mob {
  width: 66px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 195, 67, 0.2);
  border-radius: 5px;
  background: #fff;
}

.dFlex input[type="text"] {
  font-size: 16px;
  height: 48px !important;
}

.aus-mob .australiaflag {
  height: 17px;
  width: 34px;
  margin: 0 auto;
}

.aus-mob .down-arrow-flag {
  height: 9px;
  width: 13px;
}

/* Mobile Tag CSS  */

.h_80 {
  height: 80px;
}

.cbd-logo {
  height: 70px;
  width: 160px;
}

.citizen-ship .sub-head {
  font-size: 15px;
  color: #929292;
}

/* Personal details input checkbox css  */
.div-checkbox input[type="radio"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  height: 16px;
  width: 16px;
  border: 2px solid #c4b4b4;
  border-radius: 4px;
  margin: 0;
}

.div-checkbox input[type="radio"]:checked {
  background: rgb(128, 195, 67);
  border-color: rgb(128, 195, 67);
  border: 1px solid green;

}

.div-checkbox input[type="radio"]:after {
  content: "";
  position: relative;
  left: 32%;
  top: -5px;
  width: 24%;
  height: 55%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.div-checkbox input[type="radio"]:checked:after {
  display: block;
}

/* Personal details input checkbox css  */

/* Option CSS  */
option {
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 50px !important;
}

/* .add_more_btn .MuiButton-contained {
  color: #83C247;
  background-color: white;

} */
.add_more_button_align {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 5px;
}

.vHidden {
  visibility: hidden;
}

.closeArrowStyle {
  height: 20px;
  width: auto;
  cursor: pointer;
}

.driverheading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;

}

.green-border {
  border: 1px solid green;
}

.formHeading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.labelHeading {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.3264px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin: 10px auto 10px auto;
}

.follow-link {

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.3264px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.doc-check-submit-Btn {
  padding: 10px 20px 10px 20px;
  background: rgb(128, 195, 67);
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
}

.doc-check-submit-Btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media screen and (max-width: 1366px) {
  .bgGrey .MuiStepper-root {
    padding: 0 5px !important;
    margin-top: 50px;
  }

  .driverheading {
    font-size: 20px;
    margin: 20px 0;
  }
}

@media (max-width: 1200px) {  
  .bgTitleheading .bgheading {
      font-size: 20px;
  }

  .bgcheckTitle .bgheading{
    padding-left: 0;
  }

  .bgcheckTitle {
    max-width: 645px;
    margin: 0 auto;
  }

  .multiplecheckTitle {
    max-width: 98%;
  }
}

@media screen and (max-width: 992px) {
  .bgGrey .personal-detail {
    padding: 0 10px;
  }

  .videoBg div {
    width: 100% !important;
  }

  .personal-detail .MuiContainer-root,
  .personal-detail .MuiContainer-root .personal-detail {
    padding: 0 !important;
  }
}