.ForgetPassHeadingFontBold {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.TextfontNormal {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
}

.submit-button.Mui-disabled {
    background-color: #c0e1a1 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
}

.fgSpace {
    padding: 50px 115px;
}

@media screen and (max-width: 768px) {
    .fgSpace {
        padding: 20px;
    }
}

