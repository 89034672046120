.right .todayBTN {
    background: #FFFFFF;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    width: 180px;
    height: 56px;
    margin-right: 24px;
    border: 0px !important;
}

.right .monthlyBTN {
    background: #F8F8F8;
    border: 1px solid #80C343;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    width: 180px;
    height: 56px;
}
.App.myHeader{
    background-color: transparent;
    padding-top:0px;
}
.available-text {
    margin-top: 0px;
    margin-bottom:0px;
}
.rowHead{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rowdate{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.margin-bottom-28{
    margin-bottom:28px;
}
.margin-bottom-40{
    margin-bottom:40px;
}
.myHeader .toggleCalender .react-calendar{
    margin: 0;
    margin-top: -30px;
}