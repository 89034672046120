@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");

.mainCalendar .react-calendar__navigation {
    background-color: transparent;
    color: #fff;
    border-radius: 8px 8px 0 0;
    margin-bottom: 0;
    font-size: 25px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 10px;
}

.mainCalendar .react-calendar {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: none;
    background: transparent;
    margin-bottom: 40px;
    z-index: 1003;

}

.react-calendar__navigation {
    justify-content: space-between;
}

.mainCalendar .react-calendar__viewContainer {
    min-height: 820px;
    border-radius: 24px;
    background: white;
    border: 1px solid #e1e1e1;
    width: 100%;
    margin-top: 10px;
    border-bottom: none;
}

.mainCalendar .react-calendar__navigation__prev2-button {
    display: none;
}

.mainCalendar .react-calendar__navigation__next2-button {
    display: none;
}

.mainCalendar .react-calendar .react-calendar__month-view__days button {
    border-left: solid 1px #e1e1e1;
    border-bottom: solid 1px #e1e1e1;
    height: 150px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-top: 28px;
    font-size: 18px;
}

.mainCalendar .react-calendar .react-calendar__month-view__days button:nth-child(1),
.react-calendar .react-calendar__month-view__days button:nth-child(8),
.react-calendar .react-calendar__month-view__days button:nth-child(15),
.react-calendar .react-calendar__month-view__days button:nth-child(22),
.react-calendar .react-calendar__month-view__days button:nth-child(29),
.react-calendar .react-calendar__month-view__days button:nth-child(36) {
    border-left: none;
}

.mainCalendar .react-calendar__tile--now {
    background: #80C343;
}

.mainCalendar .react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #80C343;
}

.mainCalendar .react-calendar__tile--active {
    background: #80C343;
}

.mainCalendar .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #80C343;
}

.mainCalendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
    border-left: solid 1px #e1e1e1;
    border-bottom: solid 1px #e1e1e1;
    height: 80px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.mainCalendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.mainCalendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday:nth-child(1) {
    border-left: none;
}

.mainCalendar button.react-calendar__navigation__label {
    font-size: 22px;
    text-align: left;
    width: auto;
    flex-grow: 0 !important;
    position: relative;
}

.mainCalendar .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.mainCalendar .react-calendar__navigation__arrow {
    width: 48px;
    height: 48px;
    background: #0000001a !important;
    color: #000;
    border-radius: 50%;
    font-size: 31px;
    position: absolute;
    top: 448px;
    left: -25px;
    z-index: 9;
}

.mainCalendar .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    right: -25px;
    left: inherit;
}

.mainCalendar button.react-calendar__navigation__label:after {
    content: "";
    margin-left: 10px;
    background-image: url("../../../assets/Path.png");
    width: 10px;
    height: 20px;
    background-size: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
}

.mainCalendar .react-calendar__year-view {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 533px;
    padding: 20px;
}

.mainCalendar .react-calendar__year-view .react-calendar__year-view__months .react-calendar__tile {
    width: 25% !important;
    flex: 0 0 25% !important;
    border-radius: 10px;
    padding: 1.6em 0.5em;
}

.mainCalendar .react-calendar__year-view .react-calendar__tile--now {
    background: rgb(128 195 67 / 36%);
}

.mainCalendar .react-calendar__month-view__days__day--weekend {
    color: #000;
}

/* .react-calendar  .react-calendar__month-view__days button:nth-child(29), .react-calendar  .react-calendar__month-view__days button:nth-child(30), .react-calendar  .react-calendar__month-view__days button:nth-child(31), .react-calendar  .react-calendar__month-view__days button:nth-child(32), .react-calendar  .react-calendar__month-view__days button:nth-child(33), .react-calendar  .react-calendar__month-view__days button:nth-child(34), .react-calendar  .react-calendar__month-view__days button:nth-child(35){
    border-bottom: none;
} */

.mainCalendar .react-calendar__month-view {
    margin-bottom: -1px;
}

.mainCalendar .highlight abbr {
    color: white;
    background-color: #80C343;
    height: 32px !important;
    width: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    top: -5px;
}

.mainCalendar .react-calendar__tile:disabled {
    background-color: transparent;
    cursor: not-allowed;
}

.nav-month-arrow {
    position: relative;
}

.nav-month-arrow button {
    position: absolute;
    top: 50%;
}


.mainCalendarHeader {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mainCalendarHeader img {
    margin-left: 10px !important;
}

.calenderHead {
    display: flex;
    justify-content: space-between;
    padding: 0 47px !important;
    padding-right: 60px !important;
}

#navigation .calenderHead {
    margin-bottom: 10px;
    margin-top: 34px;
}

.calenderHead .left .todayTitle {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    margin-bottom: 4px;
}

.calenderHead .left .todaysubTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
    color: #949494;
}

.calenderHead .right .todayBTN {
    background: #FFFFFF;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    width: 180px;
    height: 56px;
    margin-right: 24px;
    border: 0px !important;
}

.calenderHead .right .sync-outlook {
    background: #FFFFFF;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    width: 180px;
    height: 56px;
    margin-left: 24px;
    border: 0px !important;
}

.calenderHead .right .monthlyBTN {
    background: #F8F8F8;
    border: 1px solid #80C343;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    width: 180px;
    height: 56px;
}

.calenderHead .right .holidayTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    padding-right: 10px;
    margin: 0px;

}

.calenderHead .right .holidayTitle::before {
    background: #80C343;
    border-radius: 4px;
    width: 36px;
    height: 16px;
    content: '' !important;
    display: block;
    margin-right: 10px;
}

@media(max-width: 1200px) {
    .calenderHead .right .todayBTN {
        width: 100px;
        margin-right: 10px;
    }

    .calenderHead .right .monthlyBTN {
        width: 100px;
    }

    .calenderHead .right .sync-outlook {
        padding: 10px 15px;
        width: auto;
        height: 56px;
        margin-left: 10px;
    }

    .mainCalendar .react-calendar .react-calendar__month-view__days button {
        height: 80px;
        padding-top: 12px;
    }

    .mainCalendar .react-calendar__viewContainer {
        min-height: 590px;
    }
}

@media screen and (max-width: 992px) {
    .calenderHead {
        padding: 15px !important;
        flex-direction: column;
        gap: 20px;
    }

    .calenderHead .right .todayBTN {
        width: 100px;
        margin-right: 10px;
    }

    .calenderHead .right .monthlyBTN {
        width: 100px;
    }

    .calenderHead .right .sync-outlook {
        padding: 10px 15px;
        width: auto;
        height: 56px;
        margin-left: 10px;
    }

    .mainCalendar .react-calendar .react-calendar__month-view__days button {
        height: 80px;
        padding-top: 12px;
    }

    .mainCalendar .react-calendar__viewContainer {
        min-height: 590px;
    }
}

@media(max-width: 767px) {

    .calendorHead {
        display: block !important;
    }

    .mainCalendarHeader {
        margin-bottom: 10px;
    }

    .calenderHead .right .todayBTN,
    .calenderHead .right .monthlyBTN,
    .calenderHead .right .sync-outlook {
        font-size: 12px;
    }

}

.eventDate {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;

    color: #000000;
}
