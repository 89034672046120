.emailInput:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.errorInput {
    border: 1px solid #ff0000 !important;
}

.error {
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 8px;
    color: #ff0000;
}

.eyeposition {
    position: relative;
}

.eyeposition .form-input {
    padding: 15px;
    border: none;
}

.passwordeyes {
    position: absolute;
    right: 25px;
    width: 22px;
    padding-top: 17px;
}

.date-box-form {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
    width: 100%;
}

.time-box {
    color: #949494;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
    width: 100%;
}

.setting-head {
    width: 100%;
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 52px;
}

.label-heading {
    letter-spacing: -0.3264px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 8px;
}

.form-group-setting {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.form-input {
    background: #FFFFFF;
    border-radius: 8px;
    color: #000000;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 16px;
    min-height: 45px;
    border: solid 1px #fff;
    outline: none;
    width: 100%;
}

.massage {
    letter-spacing: -0.3264px;
    color: #E24C4B;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
}

.form-box {
    max-width: 860px;
}

.btn-form {
    width: 100%;
    text-align: right;
}

.submit-form-btn {
    background: #80C343;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 18px;
    padding: 20px 16px;
    min-width: 200px;
    border-radius: 8px;
    border: solid 1px #80C343;
    font-weight: 600;
}

.submit-form-btn:hover {
    background: #fff;
    color: #80C343;
    border-color: #80C343;
}

.flagicon {
    position: relative;
}


.flagBox {
    padding: 11px 16px;
    min-height: 54px;
    border: 1px solid rgb(128 195 67 / 38%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 66px;
}

.pl-100 {
    padding-left: 85px;
}

.flagicon input#phone_number {
    padding-left: 75px !important;
}

.main-form {
    background: rgb(242, 242, 242);
    width: 100%;
    padding: 40px 47px;
    min-height: calc(100vh - 128px);
}

@media (max-width: 767px) {
    .main-form {
        padding: 25px 15px;
    }

    .submit-form-btn {
        font-size: 14px;
        padding: 15px 8px;
        min-width: 150px;
    }
}