.TextfontNormal {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
}

.TextfontBold {
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
}

.headingFontBold {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

/* All html label  */
.LebelText {
    font-size: 16px;
}

.checkboxLabel {
    color: "#BEBEBE";
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;

}

.termsOfServices {
    font-weight: 600;
    color: #80c342;
    font-family: 'Lexend', sans-serif;
    font-size: 14px;
    text-decoration: none;

}

.loginModalContainer {
    padding: 50px 115px;
}

.text-center {
    text-align: center;
}

.loginTitle {
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    margin: 0 0 32px 0;
}

.loginActions {
    display: flex;
    flex-direction: column;

}

.loginLinks {
    margin: 7px 0px;
    text-align: center;

}

.linkColor {
    color: #80c342;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
    text-decoration: none;
}

.email-not-found-arrow {
    width: 20px;
    height: 20px;
    padding: 20px;
}

.email-container {
    padding: 0px 100px 20px 100px;
}

.email-container-box {
    display: flex;
    align-items: center;
    justify-content: center
}

.triangle {
    width: 60px;
    height: 60px;
}

.email-form {
    text-align: center;
    font-weight: bolder;
    color: black;
    font-size: 20px;
    padding: 0px 0px;
}

.email-not-found {
    font-weight: bolder;
    font-size: 25px !important;
    font-family: 'Montserrat', sans-serif;
    margin-top: 4vh !important;

}

.email-form-text {
    color: "#7E7E7E";
    font-family: 'Montserrat', sans-serif;
    font-size: 12px !important;
    font-weight: bolder;
    width: 100%;
    height: 50;
    text-align: center;
}

.email-dialog-actions {
    display: flex;
    flex-direction: column;


}

.login-otp {
    color: #A0A0A0;
    text-align: center;
    margin-top: -18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;

}

.green {
    color: #99CF68;
}

.loginModalHeading {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin: 0 0 32px 0;
    font-size: 40px;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}

.login-title {
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.login-heading {
    text-align: center;
    font-size: 40px;

}

.arrow {
    width: 24px;
    height: 20px;
    margin: 63px 0px -87px 30px;
    cursor: pointer;
    z-index: 9999;
}

.modal-scrollbar::-webkit-scrollbar {
    width: 7px;
}

.modal-scrollbar::-webkit-scrollbar-track {
    background-color: #ECECEC;
    border-radius: 5px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #80C343;
    border-radius: 5px;
}

.err {
    text-align: center;
    margin: 5px 0 0 10px;
    color: red;
    font-size: 14px !important;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
}

.success {
    margin: 5px 0 0 10px;
    color: #80C343;
    font-size: 14px !important;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
    text-align: center;
}

.ml10 {
    margin-left: 10px;
}

.MuiDialogContent-root {
    padding: 0 0 16px 0 !important;
}

/* OTP */
.otp-text1 {
    font-size: 16px;
}

.opt-box-container input {
    background-color: #DDDDDDA3 !important;
}

.opt-box-container input::placeholder {
    font-family: "Lexend", sans-serif;
    font-size: 14px;
    font-weight: 300;
}

/* Policy and term */
.term-and-policy-header {
    font-size: 20px;
    margin: 0 0 14px 0;
}

.term-and-policy-sub-header {
    font-size: 16px;
    margin: 0 0 16px 0;
}

/* Overwrite the mui css */
.MuiFormControlLabel-root {
    align-items: start !important;
    gap: 8px;
    margin: 0 !important;

}

.MuiIconButton-root {
    padding: 0 !important;
}

.MuiFormHelperText-root {
    font-family: 'Lexend', sans-serif !important;

}

.MuiFormHelperText-contained {
    margin-left: 0 !important;
}

.sign-up-screen.MuiTypography-body1 {
    font-family: 'Lexend', sans-serif !important;
}

.MuiFilledInput-input {
    background-color: #F8F8F8 !important;
    padding: 15px !important;
}

.MuiInputBase-input {
    border-radius: 5px;
}

.MuiButton-contained {
    box-shadow: none !important;
}

.MuiIconButton-edgeEnd {
    margin-right: 0 !important;
}
.linkColor:hover {
    color: #80C343;
}
.termsOfServices:hover {
    color: #80C343;
}