@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-family: "Noto Sans", sans-serif;
  background: #f7f7f7;
  overflow-x: hidden;
}

h1 {
  font-size: 28px;
  text-align: center;
}

.btn-transparent {
  padding: 10px 25px;
  background: #fff;
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lexend', sans-serif;
}

.btn-green {
  padding: 10px 5px 10px 20px;
  background: rgb(128, 195, 67);
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Lexend', sans-serif;
}

.btn-green-full-opacity {
  padding: 10px 5px 10px 20px;
  background: rgb(128, 195, 67);
  border: 1px solid rgb(128, 195, 67);
  outline: none;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
}

.disqualifiedDrive .div-checkbox {
  margin-right: 15px !important;
}

.vehicleDetailsLabel label span {
  line-height: 25px;
}

.vehicleDetailsLabel label {
  padding-top: 12px;
  padding-bottom: 8px;
  display: inline-block;
}

.twoCheckbox .div-checkbox {
  border: 1px solid #dbf3d3;
  padding: 15px 20px;
}

.twoCheckbox .div-checkbox.selected {
  border: 1px solid #80C343;
}

.scrollContent.pl-0 {
  padding-left: 0 !important;
}

.disqualifiedDrive label {
  display: block;
  margin-bottom: 8px;
}

.disqualifiedDrive .df {
  margin-bottom: 24px;
}

.noOwnVehicle h2 {
  font-size: 16px;
  font-weight: bold;
}

.noOwnVehicle p {
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
}

.personal-detail .twoCheckbox {
  margin-top: 10px;
}

.titleDetails {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: #000;  
}

.ownVehicleTitle {
  font-size: 16px;
  color: #7d7d7d;
  font-weight: 400;
}

.div-checkbox label.yesLabel, .div-checkbox label.noLabel {
    margin-left: 0 !important;
    padding-left: 0 !important;
}


.personal-detail label {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.da {
  display: flex;
  align-items: center;
}

.da img {
  height: auto;
  width: 20px;
  padding-top: 5px;
}

sup {
  color: red;
}

.w-100 {
  width: 100%;
}

.err {
  margin: 5px 0 0 10px;
  color: red;
  font-size: 14px !important;
}