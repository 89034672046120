.thanksgiving {
    text-align: center;
    margin-top: 15px;
    min-height: 450px;
}
.thanksgiving img {
    max-height: 175px;
    min-height: 175px;
    margin: 15px 0;
}
.historyContainer {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 45px;
    width: fit-content;
    margin: 5rem auto;
}
.historyContainer p {
    margin-bottom: 0;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    background: rgb(128, 195, 67);
    padding: 10px 15px;
}