.fileUploadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-left: 40px;
}

.DocUploadHeading {
  font-weight: 500;
  font-size: 18px;
  color: #a2a2a2
}

.DocUploadHeading span {
  text-transform: capitalize;
}

.insuranceUrl {
  padding-bottom: 20px;
}

.insuranceUrl strong{
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.innerBoxselect {
  background: #eaeaeaea;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.linkInsurance {
  color: #80C343;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.linkInsurance:hover {
  color: #6c757d;
}

.DocUploadImageContainer {
  padding: 10px 15px;
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15px
}

.DocUploadImageContainer1 {
  border: 1px dashed #949494;
  border-radius: 10px
}


.UpLoadImageIcon {
  width: 30px;
  height: 30px;
  justify-content: flexStart;
  margin-right: '30px';
}


.imgBottomEle {
  text-align: center;
  margin-top: 16px
}


.UpLoadImage1 {
  width: 40px;
  height: 40px;
  justify-content: flexStart;
  margin-top: 12px;
  margin-left: 15px
}


.UploadInputField {
  font-size: 16px;
  font-weight: 400;
  color: #4c4c4c
}


.UploadInputText {
  font-size: 14px;
  font-weight: 500;
  color: #d9d9d9;
}


.UploadInputRightText {
  font-size: 14px;
  font-weight: 500;
  color: #d9d9d9;
}


.InputErrTxt {
  color: #fb7b7c;
  font-size: 0.8em
}


.fileButton {
  background-color: #f7f7f7 !important;
  padding: 3px 12px !important;
  border-radius: 6px !important;
  color: #525252 !important;
  font-size: 16px !important;
  border: none !important;
  min-width: 35px !important;
  height: 35px;
}

.fileButton img {
  width: 18px;
}

.drop-file-input {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 18px;
  margin-top: 12px;
  margin-bottom: 16px;
}

.drop-file-input-border {
  width: 100%;
  border: 1px dashed #949494;
  border-radius: 10px;
}

.drop-file-input-error-border {
  border: 1px dashed red;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: #ccc;
  font-weight: 600;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drop-file-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.drop-file-text .font-size-10 {
  margin-bottom: 6px;
}

.drop-file-text .color-black {
  margin-top: 6px;
}

.drop-img {
  display: flex;
  gap: 40px;
  align-items: center;
}

.UpLoadImageIcon-box {
  display: flex;
  align-items: center;
}

.drop-file-input__label img {
  width: 50px;
  height: 50px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.font-Supports {
  margin-bottom: 4px;
}

.font-size-10 {
  font-size: 10px;
}

.drop-file-input__label .color-black {
  color: #000;
  margin-bottom: 8px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: #f5f8ff;
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}