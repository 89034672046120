.driverSettingForm {
	width: 100%;
}

.driverSettingForm .MuiFilledInput-input {
	font-family: 'Lexend', sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	letter-spacing: -0.3263999819755554px;
	color: #000000;
	background-color: #fff !important;
}

.driverSettingForm .MuiFilledInput-underline.Mui-disabled::before {
	display: none !important;
}

.driverSettingForm .MuiFilledInput-root {
	border-radius: 8px !important;
}

.profileeditForm label {
	margin-top: 20px !important;
}

.profileeditForm .Mui-error {
	margin: 5px 0 0 0 !important;
}

.profileeditForm label {
	font-size: 14px;
	color: rgb(111, 111, 111);
	font-weight: 600;
	margin: 0 0 10px 0;
	display: block;
}

.profileeditForm button {
	padding: 10px 15px 5px 15px;
	width: 100px;
	margin-left: 15px !important;
}

.driverSettingForm .passwordeyes {
	position: absolute;
	right: 15px;
	width: 20px;
	margin-top: 10px;
	top: 19px;
	padding-top: 0px !important;
}

.passwordfield {
	position: relative;
}

.profileeditForm .passwordeyes {
	position: absolute;
	right: 15px;
	width: 20px;
	margin-top: 0 !important;
}

.settings-container {
	background: rgb(242, 242, 242);
	padding: 0 60px;
	width: 100% !important;
	max-width: unset !important;
	height: 100vw;
	display: flex !important;
	align-items: stretch;
	flex-direction: column;
}

.settings-section {
	margin: 30px 0 60px 0;
}

.action-button {
	display: flex;
	justify-content: flex-end;
	margin: 20px 0 0 0;
}

.close-button {
	color: black !important;
	border-color: #80c342 !important;
}

.save-button {
	color: white !important;
	background-color: #80c342 !important;
	margin-left: 4px !important;
}

.go-to-settings-button {
	color: white !important;
	background-color: #80c342 !important;
	margin-top: 4px !important;
}

.settings-row {
	width: 600px;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 auto;
}
.rowflex{
	display: flex;
	align-items: center;
}
.arrow-button {
	width: 20px;
	cursor: pointer;
	margin-right: 10px;
}

.navigationArrow {
	padding: 10px 0px 10px 0;
    margin-top: 0px;
    width: 7px;
}

.driverSettingForm .Mui-disabled {
	color: grey !important;
	cursor: not-allowed !important;
}

.row {
	display: flex;
	align-items: center;
}

.rowSpace {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.settings-text {
	/* font-family: 'Montserrat' !important; */
	font-family: 'Montserrat','Lexend', sans-serif;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 32px !important;
	line-height: 36px !important;
}

.editPassBtnEle {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: 24px;
}

.editPassBtn {
	color: black !important;
	border: 1px solid #80c342 !important;
	padding: 5px 20px !important;
	text-transform: capitalize !important;
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
}

.form-container {
	width: 600px;
}

.settingsLabels {
	font-family: 'Lexend', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 16px;
	color: rgb(109, 109, 109);
}

.email-input {
	margin-bottom: 20px !important;
	padding: 10px 0 !important;
}

.password-input {
	padding: 10px 0 !important;
}

.disableBtn {
	background-color: #c1ed98 !important;
	cursor: not-allowed !important;
}


/* page responsive @media */
@media(max-width: 992px) {
	.settings-row {
		width: 100%;
	}

	.settings-row .MuiTypography-h4 {
		font-size: 1.5rem !important;
	}
	/* .maincalender-box{
		flex-direction: column;
		flex-wrap: wrap;
		display: inline-block !important;
	} */
	/* .sidebar{
		width: 100% !important;
		min-height: 100px !important;
		height: auto !important;
	} */
}

@media(max-width: 1200px) {
	.settings-container {
		height: calc(100vh - 80px);
	}
}