.backIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.todayBtn {
  width: 185px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  border: none;
}

.backbtnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.backDateContainer {
  display: flex;
  align-items: center;
}
.dayTimeContainer {
  margin-left: 15px;
}
.middleContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.green-box {
  width: 36px;
  height: 17px;
  background: #80c343;
  border-radius: 4px;
  margin-right: 10px;
}
.grey-box {
  width: 36px;
  height: 17px;
  background: #949494;
  border-radius: 4px;
  margin-right: 10px;
}
.slotLegends {
  display: flex;
  justify-content: space-between;
}

.slotLegendsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.slotsContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  grid-gap: 10px;
  padding: 30px;
}
.dropdownList {
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  z-index: 2;
}

.calendarDropDown {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.currentDate {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.currentTime {
  color: #949494;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.selectInductionText {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 10px;
}
.weekHead {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-left: 45px;
}
.weekContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendorContentOuter .calendorContent {
  overflow: scroll;
  margin-top: 30px;
  padding: 0;
  display: inline-block;
}

.calendorContentOuter .calendorContent .fc .fc-view-harness {
  width: 100%;
}

.calendorContentOuter .calendorContent .fc-timegrid-body {
  width: 100% !important;
}

.calendorContentOuter .calendorContent .fc-timegrid-cols table {
  width: 100% !important;
}

.weektext {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.weekIconLeft {
  margin-right: 35px;
  cursor: pointer;
}
.weekIconRight {
  margin-left: 35px;
  cursor: pointer;
}
.weekDay {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  margin-left: 35px;
}
.fc-toolbar-chunk > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 250px;
  left: 604px;
}
.fc .fc-button-primary {
  background-color: #97979773;
  border: none;
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 25px;
}
.fc .fc-toolbar-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-left: 60px;
  margin-right: 60px;
}
.fc table {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.fc .fc-view-harness {
  background: #ffff;
  height: 775px !important;
  width: 1255px;
}
.fc-v-event {
  background: #ffff;
  border: none;
}
.fc .fc-timegrid-col.fc-day-today {
  background: #ffff;
}
.fc .fc-timegrid-slot {
  border: none;
}
.fc-timegrid-event-harness-inset {
  display: block;
  margin: 10px;
  padding: 10px;
  position: relative;
}
.fc-timegrid-event-harness {
  position: relative;
  display: block;
  margin: 10px;
  padding: 10px;
}

.fc-theme-standard {
  border: none;
  padding: 10px;
  margin: 2px;
}
.fc-theme-standard th {
  border: none;
  padding: 10px;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  display: none;
}
.fc-theme-standard .fc-scrollgrid {
  border: none;
}
.fc .fc-col-header-cell-cushion {
  text-decoration: none;
  color: #000000;
}

.book-slot-blackBtn {
  background: #80c343;
  border-radius: 4px;
  height: 32px;
  width: 190px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.green-circle {
  height: 16px;
  width: 16px;
  background: #80c343;
  border-radius: 10px;
}


.popper-slotLabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-left: 5px;
}
.dialogBoxText {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
}
.booked-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}
.dialogBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 45px;
  height: 340px;
}
.notifyText {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 42px;
}
.emailText {
  line-height: 42px;
  color: #80c343;
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.downArrow {
  height: 7px;
  width: 14px;
  margin-left: 10px;
}
.fc-prev-button {
  margin-right: 30px;
}
.fc-next-button {
  margin-left: 30px;
}
.toggleCalender .react-calendar__tile {
  border-radius: 0;
}

.robot-img {
  width: 341px;
  height: 250px;
}

.fc-daygrid-day-frame a {
  background: none;
  border: none;
  margin: 10px 0;
}

.fc .fc-daygrid-day.fc-day-today {
  background: none;
}

@media screen and (max-width: 1500px) {
  .fc-toolbar-chunk > div {
    left: 474px;
  }
}

@media screen and (max-width: 1366px) {
  .fc-toolbar-chunk > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 243px;
    left: 383px;
  }
}

@media screen and (max-width: 1200px) { 
  .calendorContentOuter .calendorContent .fc .fc-view-harness {
    width: 1200px;
  }
  
  .calendorContentOuter .calendorContent .fc-timegrid-body {
    width: 1200px !important;
  }
  
  .calendorContentOuter .calendorContent .fc-timegrid-cols table {
    width: 1200px !important;
  }

  .slotLegends {
    display: block;
  }
} 

@media screen and (max-width: 820px) {
  .fc-toolbar-chunk > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 303px;
    left: 200px;
  }
}

@media (max-width: 768px) {
  .dialogBoxText {
    margin-top: 40px;
  }

  .calendarDropDown {
    font-size: 16px;
  }

  .slotLegendsItem {
    font-size: 12px;
  }
}

@media (max-width: 585px) {
   .fc .fc-toolbar-title {
    margin-left: 55px;
    margin-right: 55px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .fc-toolbar-chunk > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 290px;
    left: 4px;
  }
}
