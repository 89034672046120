.cancelAskSchedule{
    margin-right: 10px;
    border-radius: 8px !important;
    font-family: 'Lexend', sans-serif;
    width: 150px;
    height: 45px;
    border: 1px solid #80C343 !important;
    background-color: #ffffff !important;
}

.confirmAskSchedule {
    border-radius: 8px !important;
    width: 150px;
    height: 45px;
    background-color: #80C343 !important;
    border: 1px solid #80C343 !important;
    min-width: 150px !important;
    color: #fff !important;
}