@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap");

.mainBox {
    width: 100% !important;
    height: 700px;
    position: relative;
}

.mainBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background: rgba(255, 255, 255, 0.2);  
    backdrop-filter: blur(8px);*/
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) -16.47%, rgba(0, 0, 0, 0.675) 98.77%) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url("../../forgot-password/assets/global_assets/bgimg.jpg") repeat scroll 0% 0%;
    /*filter: blur(3px);*/
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    background-position: center center;
}

.resumeButton {
    border-radius: 8px !important;
    font-family: 'Lexend', sans-serif;
    width: 185px;
    height: 56px;
    border: 1px solid #80C343 !important;
    background-color: #ffffff !important;
}

.app_draft_button {
    border: 1px solid #80C343 !important;
    background-color: #ffffff !important;

}

.pending_approval_btn {
    border: 1px solid #80C343 !important;
    background-color: #ffffff !important;
    color: #80C343;
}

.pending_approval_btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.uploadButton {
    text-transform: none;
    border: 1px solid #80C343;
    border-radius: 8px !important;
    background-color: #80C343;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 20px 12px;
    min-width: 185px;
    height: 56px;
    margin: 12px;
}

.cancel-btn {
    background-color: #80C343 !important;
    border: 1px solid #80C343 !important;
    min-width: 185px !important;
    color: #fff !important;
}

.sign-Contract {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    min-width: 185px !important;
    color: #fff !important;
}

.detailContent {
    font-family: 'Lexend', sans-serif;
    font-style: "normal";
    font-weight: 700;
    font-size: "1.5rem";
    line-height: "36px";
    align-items: "start";
    color: "#D7D7D7";
}

.btn-outline-none {
    border: none !important;
    background-color: #fff !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.uploadButton.MuiButton-outlined {
    padding: 5px 20px;
}

.uploadButton img {
    margin-left: 15px;
}

@media (min-width: 1500px) {
    .containerBox {
        max-width: 1600px;
    }
}

@media (min-width: 1800px) {
    .containerBox {
        max-width: 1800px;
    }
}


.p-button .pi {
    font-size: 2rem;
}

.p-calendar {
    height: 56px;
}

.p-calendar .p-button {
    color: #80C343;
    border: none;
    background: #FFF;
}

.p-calendar .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #d4d6d2;
    opacity: 0.6;
    color: #ffffff;
    border-color: #ffffff;
}

.p-calendar input[type="text"] {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
}

.p-calendar .p-inputtext:enabled:focus {
    border-color: "transparent";
    box-shadow: none;
}

.p-calendar .p-inputtext:enabled:hover {
    border-color: "transparent";
    box-shadow: none;
}

.p-calendar .p-button:focus {
    box-shadow: none;
}

.p-calendar input::placeholder {
    color: #000000 !important;
    opacity: 1;
}

.customCalendar {
    height: "24px";
    width: "24px";
}

.customCalendar:hover {
    color: #ffffff;
}

.MuiList-root .MuiMenuItem-root {
    height: 48px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}

.MuiPaper-root .MuiPaper-rounded {
    border-radius: 8px;
}

.MuiList-root .MuiListItem-button:hover {
    background: #F0F0F0;
}

.MuiList-root .MuiMenuItem-root .MuiListItemIcon-root {
    min-width: 46px;
}

.backMaindashboard {
    width: 50%;
}

.dashboardTitle {
    font-size: 30px;
    line-height: 59px;
}

@media (max-width: 1024px) {
    .backMaindashboard {
        width: 100%;
    }

    .dashboardTitle {
        font-size: 20px;
        line-height: 32px;
    }
}